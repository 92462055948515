import { FC } from "react";

import { LeftSide } from "./components/LeftSide";
import { RightSide } from "./components/RightSide";
import classes from "./Header.module.scss";
import { HeaderProps } from "./types";

import { Container } from "shared/ui/Container";

export const Header: FC<HeaderProps> = ({ count }) => {
  return (
    <header className={classes.header}>
      <Container fluid className={classes.container}>
        <LeftSide count={count} />
        <RightSide />
      </Container>
    </header>
  );
};
