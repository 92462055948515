import { ProfileLayout } from "app/layouts/ProfileLayout";

import { NotificationsPage } from "pages/NotificationsPage";
import { NotificationsSettingsPage } from "pages/NotificationsSettingsPage";

import { createRoute, createLazyRoute } from "../utils";

export const notificationsRoutes = [
  createLazyRoute({
    path: "/profile/notifications",
    page: "NotificationsPage",
    layout: <ProfileLayout title="Уведомления | Guruleads" />,
  }),
  createLazyRoute({
    path: "/profile/notifications/settings",
    page: "NotificationsSettingsPage",
    layout: <ProfileLayout title="Уведомления | Guruleads" />,
  }),
];
