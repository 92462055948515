import { makeAutoObservable } from "mobx";

import { TFAStatusType } from "./model";

export class TFAStore {
  tfa: TFAStatusType = 1;

  constructor() {
    makeAutoObservable(this);
  }

  updateTFA = (val: TFAStatusType) => {
    this.tfa = val;
  };
}
