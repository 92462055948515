import { MainLayout } from "app/layouts/MainLayout";
import { ProfileLayout } from "app/layouts/ProfileLayout";
import { ProfileProvider } from "app/providers/ProfileProvider";
import { TFAProvider } from "app/providers/TFAProvider";

import { createLazyRoute } from "../utils";

export const profileRoutes = [
  createLazyRoute({
    path: "/profile",
    page: "ProfilePage",
    layout: <ProfileLayout title="Профиль | Guruleads" />,
    provider: <ProfileProvider />,
  }),
  createLazyRoute({
    path: "/profile/edit-user",
    page: "EditUserPage",
    layout: <MainLayout title="Профиль | Guruleads" />,
  }),
  createLazyRoute({
    path: "/profile/edit-password",
    page: "EditUserPasswordPage",
    layout: <MainLayout title="Изменение пароля | Guruleads" />,
  }),
  createLazyRoute({
    path: "/profile/api-settings",
    page: "ApiSettingsPage",
    layout: <ProfileLayout title="Настройки API | Guruleads" />,
  }),
  createLazyRoute({
    path: "/profile/tfa",
    page: "TFAPage",
    layout: <MainLayout title="Двухфакторная авторизация | Guruleads" />,
    provider: <TFAProvider />,
  }),
];
