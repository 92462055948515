import React, { FC } from "react";

import { ReactComponent as ArrowIcon } from "@material-symbols/svg-400/rounded/west.svg";
import cn from "classnames";

import classes from "./Heading.module.scss";
import { HeadingProps } from "./Heading.types";

import { Link } from "shared/ui/Link";

export const Heading: FC<HeadingProps> = ({
  className,
  children,
  icon,
  link,
  label,
  tag = "h1",
  weight = "extra-bold",
}) => {
  return React.createElement(
    tag,
    {
      className: cn(classes.heading, classes[tag], classes[weight], (link || icon) && classes.flex, className),
      "data-testid": "heading",
    },
    link && (
      <Link variant="black" to={link} className={classes.link}>
        <ArrowIcon />
      </Link>
    ),
    icon && (
      <span data-testid="heading-icon" className={classes.icon}>
        {icon}
      </span>
    ),
    <span data-testid="heading-children" className={cn(icon && classes.container)}>
      {children && <span className={cn(label && classes.content)}>{children}</span>}
      {label && <span className={classes.label}>{label}</span>}
    </span>,
  );
};
