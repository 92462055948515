import { FC } from "react";

import cn from "classnames";

import { NotificationProps } from "../model";

import classes from "./Notification.module.scss";

import { Heading } from "shared/ui/Heading";
import { HtmlComponent } from "shared/ui/HtmlComponent";
import { Status } from "shared/ui/Status";
import { Text } from "shared/ui/Text";
import { getDate } from "shared/utils";

interface Props {
  className?: string;
  preview?: boolean;
  data: NotificationProps;
}

export const Notification: FC<Props> = ({ className, preview = false, data }) => {
  return (
    <div className={cn(classes.container, preview && classes.preview, className)}>
      <div className={classes.header}>
        <Status showIndicator={false} className={classes.tag}>
          {data.typeName}
        </Status>
        <Text size="sm">{getDate(data.date)}</Text>
      </div>
      <Heading className={classes.title} tag="h3">
        {data.title}
      </Heading>
      <div className={classes.content}>
        <HtmlComponent innerHtml={data.content} />
      </div>
    </div>
  );
};
