import { FC } from "react";

import { BackgroundProps, ThumbNails } from "entities/Settings/model";

import classes from "./Background.module.scss";

import { Link } from "shared/ui/Link";

const renderPicture = (thumbnails: ThumbNails) => (
  <picture>
    <source media="(max-width: 576px)" srcSet={thumbnails.xs} />
    <source media="(max-width: 768px)" srcSet={thumbnails.sm} />
    <source media="(max-width: 992px)" srcSet={thumbnails.md} />
    <source media="(max-width: 1200px)" srcSet={thumbnails.lg} />
    <source media="(max-width: 1400px)" srcSet={thumbnails.xl} />
    <source media="(max-width: 1920px)" srcSet={thumbnails.xxl} />
    <img className={classes.background} srcSet={thumbnails.default} alt="" />
  </picture>
);

export const Background: FC<BackgroundProps> = ({ thumbnails, link }) => {
  if (link) {
    return (
      <Link className={classes.link} to={link}>
        {renderPicture(thumbnails)}
      </Link>
    );
  }
  return renderPicture(thumbnails);
};
