import { UserProps } from "entities/User/model";

import { request } from "../request";
import { setupData } from "../utils";

import { NewPassword } from "./types";

export const UserApi = () => {
  /** Информация пользователя */
  const dataUserConfig = setupData("profile.get-info");

  const getUser = async (id: string) => {
    return await request([{ ...dataUserConfig, id: id }]);
  };

  /** Редактирование информации о пользователе */
  const editUserConfig = setupData("profile.edit");

  const updateUser = async (id: string, user: UserProps) => {
    return await request([{ ...editUserConfig, id: id, params: user }]);
  };

  /** Редактирование пароля */
  const editUserPasswordConfig = setupData("profile.edit-user-password");

  const updateUserPassword = async (id: string, passwords: NewPassword) => {
    return await request([{ ...editUserPasswordConfig, id: id, params: passwords }]);
  };

  /** Баланс пользователя */
  const dataBalanceConfig = setupData("profile.get-balance");

  const getBalance = async (id: string) => {
    return await request([{ ...dataBalanceConfig, id: id }]);
  };

  /** Реферальная программа */
  const dataReferalConfig = setupData("profile.get-referral-info");

  const getReferal = async (id: string) => {
    return await request([{ ...dataReferalConfig, id: id }]);
  };

  /** Контакты пользователя */
  const dataUserContactsConfig = setupData("profile.get-contact");

  const getUserContacts = async (id: string) => {
    return await request([{ ...dataUserContactsConfig, id: id }]);
  };

  /** Кол-во активных тикетов на веба */
  const dataUserActiveTicketsConfig = setupData("profile.get-support-active-tickets-count");

  const getUserActiveTickets = async (id: string) => {
    return await request([{ ...dataUserActiveTicketsConfig, id: id }]);
  };

  /** Выход из системы */
  const logoutConfig = setupData("logout");

  const logout = async (id: string) => {
    return await request([{ ...logoutConfig, id: id }]);
  };

  return {
    dataUserConfig,
    editUserConfig,
    dataBalanceConfig,
    dataReferalConfig,
    dataUserContactsConfig,
    dataUserActiveTicketsConfig,
    logout,
    getUser,
    updateUser,
    getBalance,
    getReferal,
    getUserContacts,
    updateUserPassword,
    getUserActiveTickets,
  };
};
