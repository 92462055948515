import { ReactNode } from "react";

import { ReactComponent as AccountWalletIcon } from "@material-symbols/svg-400/rounded/account_balance_wallet.svg";
import { ReactComponent as AccountIcon } from "@material-symbols/svg-400/rounded/account_circle.svg";
import { ReactComponent as StarIcon } from "@material-symbols/svg-400/rounded/award_star.svg";
import { ReactComponent as ContractIcon } from "@material-symbols/svg-400/rounded/contract.svg";
import { ReactComponent as DownloadIcon } from "@material-symbols/svg-400/rounded/download.svg";
import { ReactComponent as NotificationsIcon } from "@material-symbols/svg-400/rounded/notifications.svg";
import { ReactComponent as SettingsIcon } from "@material-symbols/svg-400/rounded/tune.svg";
import { Path } from "react-router-dom";

import { SystemType } from "entities/User/model";

export interface RouteProps extends Omit<Partial<Path>, "search"> {
  search?: Record<string, any>;
}
export interface LinkProps {
  id: number;
  label: ReactNode;
  route: RouteProps;
  hasCount?: boolean;
}

interface Props {
  id: number;
  path?: string;
  label: ReactNode;
  hasCount?: boolean;
  hideForSystem?: SystemType;
  icon?: ReactNode;
  links?: LinkProps[];
}

export const routes: Props[] = [
  {
    id: 1,
    path: "/",
    label: "Главная",
  },
  {
    id: 2,
    label: "Статистика",
    links: [
      {
        id: 1,
        route: {
          pathname: "/stats/daily",
        },
        label: "Общая",
      },
      {
        id: 2,
        route: {
          pathname: "/stats/gambling",
        },
        label: (
          <>
            Гемблинг <StarIcon />
          </>
        ),
      },
    ],
  },
  {
    id: 3,
    label: "Офферы",
    links: [
      {
        id: 1,
        route: {
          pathname: "/offers",
        },
        label: "Все офферы",
      },
      {
        id: 2,
        route: {
          pathname: "/offers",
          search: {
            subscribed: 1,
          },
        },
        label: "Избранные офферы",
      },
      {
        id: 3,
        route: {
          pathname: "/offers",
          search: {
            inPromotion: "ртвуоаол",
          },
        },
        label: "Акционные офферы",
      },
    ],
  },
  {
    id: 4,
    path: "/promotions",
    label: "Акции",
    hideForSystem: 3,
  },
  {
    id: 5,
    label: "Биллинг",
    hasCount: true,
    links: [
      {
        id: 1,
        route: {
          pathname: "/billing",
        },
        label: "Выплаты",
        hasCount: true,
      },
      {
        id: 2,
        route: {
          pathname: "/profile/payments",
        },
        label: "Платежная информация",
      },
      {
        id: 3,
        route: {
          pathname: "/profile/contracts",
        },
        label: "Договоры",
      },
      {
        id: 4,
        route: {
          pathname: "/billing/acts",
        },
        label: "Акты",
      },
    ],
  },
  {
    id: 6,
    label: "Инструменты",
    links: [
      {
        id: 1,
        route: {
          pathname: "/showcases",
        },
        label: (
          <>
            Конструктор витрин <StarIcon />
          </>
        ),
      },
      {
        id: 2,
        route: {
          pathname: "/conversion-loaders",
        },
        label: (
          <>
            Загрузчик конверсий <StarIcon />
          </>
        ),
      },
      {
        id: 3,
        route: {
          pathname: "/back-link",
        },
        label: "Кнопка «Назад»",
      },
      {
        id: 4,
        route: {
          pathname: "/global-postbacks",
        },
        label: "Глобальные постбеки",
      },
      {
        id: 5,
        route: {
          pathname: "/parking-domain",
        },
        label: "Парковка доменов",
      },
      {
        id: 6,
        route: {
          pathname: "/broken-links",
        },
        label: "Битые ссылки",
      },
      {
        id: 7,
        route: {
          pathname: "/trafficback",
        },
        label: "Trafficback-ссылки",
      },
      {
        id: 8,
        route: {
          pathname: "/api-instructions",
        },
        label: "Инструкция к API",
      },
    ],
  },
];

export const profileRoutes: Props[] = [
  {
    id: 11,
    path: "/profile",
    label: "Мои данные",
    icon: <AccountIcon />,
  },
  {
    id: 13,
    path: "/profile/payments",
    label: "Мои реквизиты",
    icon: <AccountWalletIcon />,
  },
  {
    id: 15,
    path: "/profile/contracts",
    label: "Договоры",
    icon: <ContractIcon />,
  },
  {
    id: 16,
    path: "/profile/history",
    label: "История экспорта",
    icon: <DownloadIcon />,
  },
  {
    id: 12,
    path: "/profile/api-settings",
    label: "Настройки API",
    icon: <SettingsIcon />,
  },
  {
    id: 14,
    path: "/profile/notifications",
    label: "Уведомления",
    icon: <NotificationsIcon />,
  },
];
