import { request } from "../request";
import { TFAApi } from "../TwoFactorAuthentication";
import { UserApi } from "../User";

export const profileBatch = async (referalId: string, contactsId: string, tfaId: string) => {
  const { dataReferalConfig, dataUserContactsConfig } = UserApi();
  const { tfaStatusConfig } = TFAApi();

  return await request([
    { ...dataReferalConfig, id: referalId },
    { ...dataUserContactsConfig, id: contactsId },
    { ...tfaStatusConfig, id: tfaId },
  ]);
};
