import { NotificationApi } from "../Notification";
import { request } from "../request";
import { SettingsApi } from "../Settings";
import { UserApi } from "../User";

export const defaultBatch = async (
  userId: string,
  balanceId: string,
  notificationId: string,
  backgroundId: string,
  activeTicketsId: string,
) => {
  const { dataUserConfig, dataBalanceConfig, dataUserActiveTicketsConfig } = UserApi();
  const { dataNotificationCount } = NotificationApi();
  const { dataBackgroundConfig } = SettingsApi();

  return await request([
    { ...dataUserConfig, id: userId },
    { ...dataBalanceConfig, id: balanceId },
    { ...dataNotificationCount, id: notificationId },
    { ...dataBackgroundConfig, id: backgroundId },
    { ...dataUserActiveTicketsConfig, id: activeTicketsId },
  ]);
};
