import { SettingProps } from "../model";

export const notificationsMockUnread = [
  {
    id: 1,
    content:
      "<p>Мы пишем тебе по важному 2 вопросу, Здравствуйте пользователь, мы что-то сделали примите меры вот в письме жеж показываем первое предложение и из него должен считываться посыл.</p><p>Сморчки растут весной в лесах, парках, садах, степях. В больших количествах их можно встретить на третий, иногда на четвёртый год после лесных пожаров. На старых пожарищах могут регулярно расти каждый год, хотя и в меньших количествах. Иногда растут на месте вырубленных лесов. Считается, хотя это и не доказано, что сморчки могут образовывать микоризу с определёнными видами деревьев (особенно с ясенем). Сморчки являются сапрофитами, поэтому существует теоретическая возможность разведения сморчков как культуры.</p>",
    date: new Date(),
    isRead: false,
    title: "Test title 1",
    typeName: "Офферы",
    typeValue: 4,
  },
  {
    id: 2,
    content: "<p>Hello world and people</p>",
    date: new Date(),
    isRead: false,
    title: "Test title 2",
    typeName: "Выплаты",
    typeValue: 4,
  },
  {
    id: 3,
    content:
      "<p>Мы пишем тебе по важному 2 вопросу, Здравствуйте пользователь, мы что-то сделали примите меры вот в письме жеж показываем первое предложение и из него должен считываться посыл.</p>",
    date: new Date(),
    isRead: false,
    title: "Test title 3",
    typeName: "Офферы",
    typeValue: 4,
  },
  {
    id: 4,
    content: "<p>Hello world and people</p>",
    date: new Date(),
    isRead: false,
    title: "Test title 4",
    typeName: "Выплаты",
    typeValue: 4,
  },
];

export const notificationsMockRead = [
  {
    id: 11,
    content:
      "<p>Мы пишем тебе по важному 2 вопросу, Здравствуйте пользователь, мы что-то сделали примите меры вот в письме жеж показываем первое предложение и из него должен считываться посыл.</p><p>Сморчки растут весной в лесах, парках, садах, степях. В больших количествах их можно встретить на третий, иногда на четвёртый год после лесных пожаров. На старых пожарищах могут регулярно расти каждый год, хотя и в меньших количествах. Иногда растут на месте вырубленных лесов. Считается, хотя это и не доказано, что сморчки могут образовывать микоризу с определёнными видами деревьев (особенно с ясенем). Сморчки являются сапрофитами, поэтому существует теоретическая возможность разведения сморчков как культуры.</p>",
    date: new Date(),
    isRead: true,
    title: "Test title 1",
    typeName: "Офферы",
    typeValue: 4,
  },
  {
    id: 12,
    content: "<p>Hello world and people</p>",
    date: new Date(),
    isRead: true,
    title: "Test title 2",
    typeName: "Выплаты",
    typeValue: 4,
  },
  {
    id: 13,
    content:
      "<p>Мы пишем тебе по важному 2 вопросу, Здравствуйте пользователь, мы что-то сделали примите меры вот в письме жеж показываем первое предложение и из него должен считываться посыл.</p>",
    date: new Date(),
    isRead: true,
    title: "Test title 3",
    typeName: "Офферы",
    typeValue: 4,
  },
  {
    id: 14,
    content: "<p>Hello world and people</p>",
    date: new Date(),
    isRead: true,
    title: "Test title 4",
    typeName: "Выплаты",
    typeValue: 4,
  },
  {
    id: 15,
    content:
      "<p>Сморчки растут весной в лесах, парках, садах, степях. В больших количествах их можно встретить на третий, иногда на четвёртый год после лесных пожаров. На старых пожарищах могут регулярно расти каждый год, хотя и в меньших количествах. Иногда растут на месте вырубленных лесов. Считается, хотя это и не доказано, что сморчки могут образовывать микоризу с определёнными видами деревьев (особенно с ясенем). Сморчки являются сапрофитами, поэтому существует теоретическая возможность разведения сморчков как культуры.</p>",
    date: new Date(),
    isRead: true,
    title: "Test title 5",
    typeName: "Офферы",
    typeValue: 2,
  },
  {
    id: 16,
    content: "<p>Hello world and people</p>",
    date: new Date(),
    isRead: true,
    title: "Test title 6",
    typeName: "Выплаты",
    typeValue: 2,
  },
];

export const notificationsCategoriesMock = [
  {
    id: -1,
    name: "Все уведомления",
  },
  {
    id: 1,
    name: "Система",
  },
  {
    id: 2,
    name: "Офферы",
  },
  {
    id: 3,
    name: "Статистика",
  },
  {
    id: 4,
    name: "Выплаты",
  },
  {
    id: 5,
    name: "Акции",
  },
  {
    id: 6,
    name: "Другое",
  },
];

export const settings: SettingProps[] = [
  {
    triggerId: 9,
    triggerName: "Дайджест",
    categoryId: 1,
    categoryName: "1",
    subscriptions: [
      {
        id: 63991,
        status: 1,
        channelId: 1,
        channelType: "mail",
        channelName: "Почта",
        isSystem: 0,
      },
    ],
  },
  {
    triggerId: 20,
    triggerName: "Комментарии",
    categoryId: 1,
    categoryName: "1",
    subscriptions: [
      {
        id: 10675,
        status: 1,
        channelId: 1,
        channelType: "mail",
        channelName: "Почта",
        isSystem: 1,
      },
    ],
  },
  {
    triggerId: 33,
    triggerName: "Изменения статуса избранного оффера",
    categoryId: 2,
    categoryName: "2",
    subscriptions: [
      {
        id: 63995,
        status: 1,
        channelId: 1,
        channelType: "mail",
        channelName: "Почта",
        isSystem: 0,
      },
      {
        id: null,
        status: 1,
        channelId: 3,
        channelType: "screen",
        channelName: "Личный кабинет",
        isSystem: 0,
      },
    ],
  },
  {
    triggerId: 10,
    triggerName: "Новый оффер",
    categoryId: 2,
    categoryName: "2",
    subscriptions: [
      {
        id: 64002,
        status: 1,
        channelId: 1,
        channelType: "mail",
        channelName: "Почта",
        isSystem: 0,
      },
      {
        id: null,
        status: 1,
        channelId: 3,
        channelType: "screen",
        channelName: "Личный кабинет",
        isSystem: 1,
      },
    ],
  },
];

export const categories = [
  {
    id: 1,
    name: "Система",
  },
  {
    id: 2,
    name: "Офферы",
  },
  {
    id: 3,
    name: "Статистика",
  },
  {
    id: 4,
    name: "Выплаты",
  },
  {
    id: 5,
    name: "Акции",
  },
  {
    id: 6,
    name: "Другое",
  },
];

export const channels = [
  {
    id: 1,
    name: "Почта",
    type: "mail",
  },
  {
    id: 3,
    name: "Личный кабинет",
    type: "screen",
  },
];
