import { request } from "../request";
import { setupData } from "../utils";

export const NotificationApi = () => {
  const dataNotification = setupData("profile.get-notification-all");

  const getNotifications = async (id: string, params: any) => {
    const result = await request([{ ...dataNotification, id: id, params: params }]);
    return { result: result, data: dataNotification };
  };

  const dataNotificationCount = setupData("profile.get-notification-count");

  const getNotificationCount = async (id: string) => {
    const result = await request([{ ...dataNotificationCount, id: id }]);
    return { result: result, data: dataNotificationCount };
  };

  return {
    dataNotification,
    dataNotificationCount,
    getNotifications,
    getNotificationCount,
  };
};
