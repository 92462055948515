import { request } from "../request";
import { setupData } from "../utils";

import { QrCodeProps, ActiveteProps, DeactiveteProps } from "./types";

export const TFAApi = () => {
  /** Генерация кода */
  const generateCodeConfig = setupData("profile.send-tfa-mail-code");

  const generateCode = async (id: string) => {
    return await request([{ ...generateCodeConfig, id: id }]);
  };

  /** Получение qr кода */
  const qrCodeConfig = setupData("profile.get-tfa-qr-code");

  const getQrCode = async (id: string, params: QrCodeProps) => {
    return await request([{ ...qrCodeConfig, id: id, params: params }]);
  };

  /** Активация */
  const activateTfaConfig = setupData("profile.activate-tfa");

  const activateTfa = async (id: string, params: ActiveteProps) => {
    return await request([{ ...activateTfaConfig, id: id, params: params }]);
  };

  /** Деaктивация */
  const deactivateTfaConfig = setupData("profile.inactivate-tfa");

  const deactivateTfa = async (id: string, params: DeactiveteProps) => {
    return await request([{ ...deactivateTfaConfig, id: id, params: params }]);
  };

  /** Cтатуса аутентификации гугла */
  const tfaStatusConfig = setupData("profile.get-tfa-google-status");

  const getTfaStatus = async (id: string, params: DeactiveteProps) => {
    return await request([{ ...tfaStatusConfig, id: id, params: params }]);
  };

  return {
    qrCodeConfig,
    tfaStatusConfig,
    activateTfaConfig,
    generateCodeConfig,
    deactivateTfaConfig,
    getQrCode,
    activateTfa,
    generateCode,
    getTfaStatus,
    deactivateTfa,
  };
};
