import { FC } from "react";

import { ReactComponent as ArrowIcon } from "@material-symbols/svg-400/rounded/arrow_right_alt.svg";

import classes from "./ErrorBoundary.module.scss";

import { Button } from "shared/ui/Button";
import { Heading } from "shared/ui/Heading";
import { Text } from "shared/ui/Text";

interface Props {
  code?: number;
  text: string;
}

const REDIRECT_URL = "/";

export const ErrorBoundary: FC<Props> = ({ text, code }) => {
  return (
    <div className={classes.container}>
      {code && <div className={classes.code}>{code}</div>}
      <Heading tag="h1" weight="semi-bold">
        Упс!
      </Heading>
      <Text className={classes.text} size="xl" height="big">
        {text}
      </Text>
      <Button iconRight={<ArrowIcon />} size="medium" href={REDIRECT_URL} className={classes.button}>
        Перейти на главную
      </Button>
    </div>
  );
};
