import { FC, useState } from "react";

import { ReactComponent as CloseIcon } from "@material-symbols/svg-400/rounded/close.svg";
import cn from "classnames";
import { observer } from "mobx-react-lite";

import { useStore } from "entities/context";
import { NotificationProps } from "entities/Notification/model";
import { Notification } from "entities/Notification/ui";

import classes from "./NotificationCard.module.scss";

import { Button } from "shared/ui/Button";
import { Checkbox } from "shared/ui/Checkbox";
import { Modal } from "shared/ui/Modal";
import { Text } from "shared/ui/Text";

interface Props {
  notification: NotificationProps;
  preview?: boolean;
  showDelete?: boolean;
  setIds?: (ids: number[]) => void;
  ids?: number[];
}

export const NotificationCard: FC<Props> = observer(
  ({ notification, showDelete = false, preview = false, setIds, ids }) => {
    const { notificationStore } = useStore();
    const [openDelete, setOpenDelete] = useState(false);

    return (
      <div className={cn(classes.container, preview && classes.preview)}>
        {showDelete && (
          <Checkbox
            className={classes.checkbox}
            checked={ids?.includes(notification.id)}
            onChange={() => {
              if (setIds && ids && !ids?.includes(notification.id)) {
                setIds([...ids, notification.id]);
              } else {
                setIds && ids && setIds(ids.filter((val) => val !== notification.id));
              }
            }}
          />
        )}
        <Notification data={notification} preview={preview} />
        <div className={classes.control}>
          {showDelete && (
            <Button
              onClick={() => setOpenDelete(true)}
              className={classes.button}
              size="small"
              variant="text"
              theme="secondary"
              icon={<CloseIcon className={cn(classes.icon, classes.close)} />}
            />
          )}
          {!notification.isRead && (
            <Button
              onClick={() => notificationStore.read([notification.id])}
              className={classes.button}
              size="big"
              variant="text"
              theme="secondary"
              icon={<CloseIcon className={cn(classes.icon, classes.close)} />}
            />
          )}
        </div>
        <Modal title="Удалить уведомление" open={openDelete} setOpen={setOpenDelete}>
          <Text className={classes["text-with-offset"]} size="xl">
            Вы уверены, что хотите удалить 1 уведомление?
          </Text>
          <div className={classes.buttons}>
            <Button
              onClick={() => {
                notificationStore.delete([notification.id]);
                setOpenDelete(false);
              }}
            >
              Удалить
            </Button>
            <Button variant="text" theme="secondary" onClick={() => setOpenDelete(false)}>
              Отменить
            </Button>
          </div>
        </Modal>
      </div>
    );
  },
);
