import uniqid from "uniqid";

import { UserProps } from "../model";

import { type CurrencyCodeType } from "shared/utils";

export const userMocks: UserProps = {
  id: 1,
  login: "mail.mymail@mail.com",
  password: "123456qwerty",
  firstName: "Михаил",
  lastName: "Овчинников",
  accessToken: uniqid(),
  system: 1,
  invoicesCount: 2,
  taskCount: 0,
  hasReferal: true,
};

export const userMocksWithNoPromotions: UserProps = {
  id: 1,
  login: "mail.mymail@mail.com",
  password: "123456qwerty",
  firstName: "Михаил",
  lastName: "Овчинников",
  accessToken: uniqid(),
  system: 3,
  invoicesCount: 2,
  taskCount: 0,
  hasReferal: true,
};
