import { FC } from "react";

import cn from "classnames";
import { observer } from "mobx-react-lite";

import { useStore } from "entities/context";

import { NavigationProps } from "../../types";

import classes from "./Navigation.module.scss";

import { Dropdown } from "widgets/Header/components/Dropdown";

import { routes } from "shared/routes/routes";
import { NavLink } from "shared/ui/NavLink";

export const Navigation: FC<NavigationProps> = observer(({ count, mobile = false, setOpen }) => {
  const {
    userStore: { userSystem },
  } = useStore();
  return (
    <div data-testid="navigation-test" className={cn(classes.navigation, mobile && classes.mobile)}>
      {routes.map((route) => {
        if (route.hideForSystem !== userSystem) {
          if (route.links && route.links.length) {
            return (
              <Dropdown
                count={route.hasCount ? count : null}
                key={route.id}
                label={route.label}
                content={route.links}
                setOpenBurger={setOpen}
              />
            );
          }
          return (
            <NavLink onClick={() => setOpen && setOpen(false)} key={route.id} to={route.path ?? ""}>
              {route.label}
            </NavLink>
          );
        }
        return null;
      })}
    </div>
  );
});
