import { makeAutoObservable } from "mobx";

import { notificationsMockUnread } from "./mock";
import { NotificationProps } from "./model";

// import { NotificationApi } from "shared/api";

// const { getNotifications } = NotificationApi();

export class NotificationPreviewStore {
  notifications: NotificationProps[] = [];
  count = 0;
  loading = false;

  constructor() {
    makeAutoObservable(this);
    this.loadPreviewNotifications();
  }

  loadPreviewNotifications = async () => {
    this.loading = true;
    this.update(notificationsMockUnread);
    this.loading = false;
  };

  update = (val: NotificationProps[]) => {
    this.notifications = [...val];
  };

  updateCount = (val: number) => {
    this.count = val;
  };

  delete = (ids: number[]) => {
    this.notifications = this.notifications.filter((notification) => !ids.includes(notification.id));
  };

  read = (ids: number[]) => {
    this.notifications = this.notifications.map((notification) => {
      if (ids.includes(notification.id)) {
        return { ...notification, isRead: true };
      }
      return notification;
    });
  };

  readAll = () => {
    this.notifications = this.notifications.map((notification) => {
      return { ...notification, isRead: true };
    });
  };
}
