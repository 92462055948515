import { FC, useState } from "react";

import { ReactComponent as MenuIcon } from "@material-symbols/svg-400/rounded/menu.svg";

import { HeaderProps } from "../../types";
import { BurgerButton } from "../BurgerButton";
import { Navigation } from "../Navigation";

import classes from "./LeftSide.module.scss";

import { Drawer } from "shared/ui/Drawer";
import { Link } from "shared/ui/Link";
import { Logo } from "shared/ui/Logo";

export const LeftSide: FC<HeaderProps> = ({ count = 0 }) => {
  const [openBurger, setOpenBurger] = useState(false);
  return (
    <>
      <div className={classes.left}>
        <BurgerButton className={classes.burger} open={openBurger} setOpen={setOpenBurger}>
          <MenuIcon className={classes.menu} />
        </BurgerButton>
        <Link to="/dashboard" className={classes.logo}>
          <Logo />
        </Link>
        <Navigation count={count ?? null} />
      </div>
      <Drawer className={classes.drawer} theme="dark" position="left" open={openBurger} setOpen={setOpenBurger}>
        <Navigation mobile setOpen={setOpenBurger} />
      </Drawer>
    </>
  );
};
