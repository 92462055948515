import { FC } from "react";

import { Grid, Col } from "shared/ui/Grid";
import { Skeleton as SkeletonComponent } from "shared/ui/Skeleton";

export const Skeleton: FC = () => {
  return (
    <Grid>
      <Col span={12}>
        <div style={{ padding: "18px 16px", borderBottom: "1px solid #E9E9E9" }}>
          <SkeletonComponent columns={5} rows={1} />
          <SkeletonComponent columns={4} rows={2} />
          <SkeletonComponent columns={12} rows={4} />
        </div>
        <div style={{ padding: "18px 16px", borderBottom: "1px solid #E9E9E9" }}>
          <SkeletonComponent columns={5} rows={1} />
          <SkeletonComponent columns={4} rows={2} />
          <SkeletonComponent columns={12} rows={4} />
        </div>
        <div style={{ padding: "18px 16px", borderBottom: "1px solid #E9E9E9" }}>
          <SkeletonComponent columns={5} rows={1} />
          <SkeletonComponent columns={4} rows={2} />
          <SkeletonComponent columns={12} rows={4} />
        </div>
        <div style={{ padding: "18px 16px", borderBottom: "1px solid #E9E9E9" }}>
          <SkeletonComponent columns={5} rows={1} />
          <SkeletonComponent columns={4} rows={2} />
          <SkeletonComponent columns={12} rows={4} />
        </div>
      </Col>
    </Grid>
  );
};
