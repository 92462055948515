import { FC } from "react";

import { isRouteErrorResponse, useRouteError } from "react-router-dom";

import { ErrorBoundary } from "widgets/ErrorBoundary";

export const Page: FC = () => {
  const error = useRouteError();
  if (isRouteErrorResponse(error)) {
    return (
      <ErrorBoundary code={error.status} text="Что-то пошло не так, попробуйте позже или перезагрузите страницу" />
    );
  } else {
    return <ErrorBoundary text="Что-то пошло не так, перезагрузите страницу" />;
  }
};
